<template>
  <section class="section container">
    <nav class="breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <a>Administração</a>
        </li>
        <li class="is-active">
          <a>Dashboard</a>
        </li>
      </ul>
    </nav>

    <b-collapse class="card m-b-md" :open="false" aria-id="filterContent">
      <div
        slot="trigger"
        slot-scope="props"
        class="card-header"
        role="button"
        aria-controls="filterContent"
      >
        <p class="card-header-title">Filtro</p>
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'caret-down' : 'caret-up'"></b-icon>
        </a>
      </div>
      <div class="card-content">
        <form @submit.prevent="getStatus">
          <b-field label="Data de Inicio">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.initDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <b-field label="Data de Fim">
            <b-datepicker
              :month-names="datePicker[0]"
              :day-names="datePicker[1]"
              v-model="query.endtDate"
              icon="calendar-alt"
            ></b-datepicker>
          </b-field>

          <button class="button is-primary">Buscar</button>
        </form>
      </div>
    </b-collapse>

    <h1 class="m-b-md">Pesquisa do dia: {{date}}</h1>
    <div class="columns is-multiline">
      <div class="column is-3">
        <div class="box">
          <h1>Fale conosco fechado:</h1>
          <p>Mês: {{data.ContactUsClosedThisMonth}}</p>
          <p>Semana: {{data.ContactUsClosedThisWeek}}</p>
          <p>Hoje: {{data.ContactUsClosedToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Fale conosco em atendimento:</h1>
          <p>Mês: {{data.ContactUsInProcessThisMonth}}</p>
          <p>Semana: {{data.ContactUsInProcessThisWeek}}</p>
          <p>Hoje: {{data.ContactUsInProcessToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Fale conosco aberto:</h1>
          <p>Mês: {{data.ContactUsOpenThisMonth}}</p>
          <p>Semana: {{data.ContactUsOpenThisWeek}}</p>
          <p>Hoje: {{data.ContactUsOpenToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Fale conosco geral:</h1>
          <p>Mês: {{data.ContactUsThisMonth}}</p>
          <p>Semana: {{data.ContactUsThisWeek}}</p>
          <p>Hoje: {{data.ContactUsToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Questões respondidas:</h1>
          <p>Mês: {{data.amountQuestionAnsweredThisMonth}}</p>
          <p>Semana: {{data.amountQuestionAnsweredThisWeek}}</p>
          <p>Hoje: {{data.amountQuestionAnsweredToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Exames finais:</h1>
          <p>Mês: {{data.finalExamsThisMonth}}</p>
          <p>Semana: {{data.finalExamsThisWeek}}</p>
          <p>Hoje: {{data.finalExamsToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Exames finais aprovado:</h1>
          <p>Mês: {{data.finalExamsApprovedThisMonth}}</p>
          <p>Semana: {{data.finalExamsApprovedThisWeek}}</p>
          <p>Hoje: {{data.finalExamsApprovedToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>Exames realizados:</h1>
          <p>Mês: {{data.examsThisMonth}}</p>
          <p>Semana: {{data.examsThisWeek}}</p>
          <p>Hoje: {{data.examsToday}}</p>
        </div>
      </div>

      <div class="column is-3">
        <div class="box">
          <h1>logins:</h1>
          <p>Mês: {{data.loginsThisMonth}}</p>
          <p>Semana: {{data.loginsThisWeek}}</p>
          <p>Hoje: {{data.loginsToday}}</p>
        </div>
      </div>

      <div class="column is-3" v-if="hasProfile('admin')">
        <div class="box">
          <p>Quantidade de questões: {{data.amountQuestion}}</p>
        </div>
      </div>

      <div class="column is-3" v-if="hasProfile('admin')">
        <div class="box">
          <p>Quantidade de cursos: {{data.amountCourse}}</p>
        </div>
      </div>

      <div class="column is-3" v-if="hasProfile('admin')">
        <div class="box">
          <p>Quantidade de lições: {{data.amountLesson}}</p>
        </div>
      </div>

      <div class="column is-3" v-if="hasProfile('admin')">
        <div class="box">
          <p>Quantidade de módulos: {{data.amountModule}}</p>
        </div>
      </div>

      <div class="column is-3" v-if="hasProfile('admin')">
        <div class="box">
          <p>Quantidade de grupos: {{data.amountTeam}}</p>
        </div>
      </div>

      <div class="column is-3" v-if="hasProfile('admin')">
        <div class="box">
          <p>Quantidade de usuários: {{data.amountUser}}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";

import { hasProfile } from "@/global/auth";
export default {
  data() {
    return {
      hasProfile,
      data: [],
      query: {},
      date: new Date().toLocaleDateString("pt-BR"),
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ]
    };
  },
  methods: {
    convetData(data, end = false) {
      const temp = new Date(data);
      temp.setUTCHours(0,0,0,0);
      if(end) temp.setUTCHours(23,59,59,59)
      return temp.toISOString();
    },
    getStatus() {
      Axios.get(
        `/v1/stats?startDate=${
          this.query.initDate ? this.convetData(this.query.initDate) : ""
        }&endDate=${
          this.query.endtDate ? this.convetData(this.query.endtDate, true) : ""
        }`
      )
        .then(data => {
          if (data.data) {
            this.data = data.data;
            if (this.query.initDate)
              this.date = new Date(this.query.initDate).toLocaleDateString(
                "pt-BR"
              );
            if (this.query.endtDate)
              this.date +=
                " a " +
                new Date(this.query.endtDate).toLocaleDateString("pt-BR");
          }
        })
        .catch(erro => {
          console.error(erro);
        });
    }
  },
  mounted() {
    this.getStatus();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-weight: bold;
}
</style>